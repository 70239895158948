<template lang="pug">
.main-wrapper.procedimentos-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-3
					Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
				.p-col-9.ta-right
					h1.text-header.text-secondary MedClub / <b>Procedimentos do Estabelecimento</b>

		Dialog.dialogApagar(header='Remover procedimento' :visible.sync='dialogApagar' :modal='true')
			p Deseja remover o procedimento <b>{{ dialogApagar_data.nm_procedimento }}</b>?
			.ta-center.my-4
				ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
				Button.p-button-danger(v-else label='Remover' @click='remove()')

		Dialog(header='Subgrupos de ' :visible.sync='dialogSubgrupos' :modal='true')
			.ta-center(style='max-width: 500px')
				DataTable(:value="subgrupos")
					//- Column(headerStyle='width: 5%' bodyStyle='text-align:center' header='Status')
					//- 	template(#body='props')
					//- 		ProgressSpinner.waitingStatus(v-if='waitingStatus === props.data.ie_valor || waitingSelecionados.find(a=> a === props.data.id)' strokeWidth='6')
					//- 		.status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click='alterarStatus(props.data.ie_valor)')
					//- 			i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
					Column(headerStyle='width: 4%;' field='ie_valor' header='Código')
						template(#body='props')
							span(v-tooltip.top="props.data.ie_valor" style='cursor: pointer;') {{ props.data.ie_valor }}
					Column(headerStyle='width: 15%;' field='ds_valor' header='Subgrupo')
						template(#body='props')
							span(v-tooltip.top="props.data.ds_valor" style='cursor: pointer;') {{ props.data.ds_valor }}
		Dialog.dialogEditarObservacoes(header='Editar observações' :visible.sync='dialogEditarObservacoes' :modal='true')
			.p-grid.p-fluid
				.p-col-12
					span <b>Estabelecimento: </b> {{ dialogEditarObservacoes_data.nm_estabelecimento }}<br>
					span <b>Procedimento: </b> {{ dialogEditarObservacoes_data.nm_procedimento }}
					span(v-if='dialogEditarObservacoes_data.ds_procedimento') - {{ dialogEditarObservacoes_data.ds_procedimento }}
				.p-col-12
					label.form-label Informações de atendimento:
					Textarea.textarea-informacoes(v-model='dialogEditarObservacoes_data.ds_informacoes' placeholder='(Opcional)' :autoResize="false" rows="5")
				.p-col-12
					label.form-label Preparo do procedimento:
					Textarea.textarea-preparo(v-model='dialogEditarObservacoes_data.ds_preparo' placeholder='(Opcional)' :autoResize="false" rows="5")
				.p-col-12.ta-center
					ProgressSpinner.waitingObservacoes(v-if='waitingObservacoes' strokeWidth='6')
					Button(v-else label='Salvar' style='max-width:160px' @click='salvarObservacoes')

		Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' v-if='waiting == false || estabelecimentoLoaded' mode="indeterminate")
			.p-grid.p-fluid
				.p-col-12.p-md-4
					label.form-label Nome do Estabelecimento:
					.p-inputgroup
						MultiSelect(
							:options='options.estabelecimentos'
							placeholder='TODOS'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							:filter='true'
							v-model='filters.cd_estabelecimento_list'
							@change='applyFilters(1)'
						)
				.p-col-12.p-md-4
					MultiSelectEspecialidades(
						@lista-especialidades='mudaEspecialidades' :preSelected = 'filters.cd_especialidades'
					)
				.p-col-12.p-md-4
					label.form-label Procedimento:
					.p-inputgroup
						InputText(placeholder='Procedimento'
							@keyup.enter.native='applyFilters()'
							@input='checkEmpty_ds_procedimento()'
							v-model='filters.ds_procedimento'
							v-tooltip.top.focus="'Aperte ENTER para pesquisar'"
						)
						Button(icon='jam jam-search' @click='applyFilters()')
				.p-col-12.p-md-3
					label.form-label Tipo de Procedimento:
					.p-inputgroup
						MultiSelect(
							:options='options.tiposProcedimento'
							placeholder='TODOS'
							optionLabel='ds_valor'
							optionValue='ie_valor'
							dataKey='ie_valor'
							v-model='filters.ie_tipo_procedimento'
							@change='applyFilters()'
						)
				.p-col-12.p-md-3
					label.form-label Subgrupos:
					.p-inputgroup
						MultiSelect(
							:filter='true'
							:options='options.subgrupos'
							placeholder='TODOS'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							v-model='filters.cd_subgrupos'
							@change='applyFilters()'
						)
				.p-col-12.p-md-3
					label.form-label Grupos:
					.p-inputgroup
						MultiSelect(
							:options='options.grupo_procedimentos'
							placeholder='Selecione'
							optionLabel='ds_descricao'
							optionValue='id'
							dataKey='id'
							v-model='filters.cd_grupo_procedimento'
							@hide='applyFilters()'
						)
				.p-col-12.p-md-3
					label.form-label Mnemônico:
					.p-inputgroup
						InputText(placeholder='Mnemônico'
							@keyup.enter.native='applyFilters()'
							v-model='filters.ds_mnemonico'
							v-tooltip.top.focus="'Aperte ENTER para pesquisar'"
						)
						Button(icon='jam jam-search' @click='applyFilters()')
				.p-col-12.p-md-3
					label.form-label Status:
					.p-inputgroup
						Dropdown(
							:options='options.ie_status'
							@change="applyFilters()"
							placeholder='TODOS'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							:filter='true'
							v-model='filters.ie_status'
						)
				.p-col-12.p-md-3
					label.form-label Restrito:
					.p-inputgroup
						Dropdown(
							:options='options.ie_restrito'
							@change="applyFilters()"
							placeholder='TODOS'
							optionLabel='label'
							optionValue='value'
							dataKey='value'
							:filter='true'
							v-model='filters.ie_restrito'
						)
				.p-col-12.p-md-3
					label.form-label Grupos de Estabelecimentos:
					.p-inputgroup
						MultiSelect(
							:options='options.ie_grupo_estabelecimento'
							placeholder='Selecione'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							v-model='filters.ie_grupo_estabelecimento'
							@hide='applyFilters()'
						)
				.p-col-12.p-md-3
					label.form-label Tipo de agenda:
					.p-inputgroup
						MultiSelect(
							:options='options.tipo_agenda'
							placeholder='Selecione'
							optionLabel='label'
							optionValue='value'
							dataKey='value'
							v-model='filters.ie_tipo_agenda'
							@change='applyFilters()'
						)

		.ta-right.my-2
			Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/procedimentos-estabelecimento/salvar/0/')")
			.ta-right
				p.text-soma <b>Selecionados:</b> {{ selecionados.length }}
				//ProgressSpinner(v-if="waitingSelecionarVarios" strokeWidth="3" style="width: 20px; padding:0")
				//span(v-else)
				Button.p-button-success(
					v-tooltip.top="'Ativar selecionados'"
					:disabled='!selecionados.length || waitingSelecionarVarios'
					icon='jam jam-check'
					@click='adicionarVarios(); spinSelecionados()')
				Button.p-button-danger.ml-1(
					v-tooltip.left="'Inativar selecionados'"
					:disabled='!selecionados.length || waitingSelecionarVarios'
					icon='jam jam-minus'
					@click='retirarVarios(); spinSelecionados()')
		div
			InputSwitch.mr-2(v-model='mostrarSelecionados')
			label.label-mostrar-selecionados Mostrar somente selecionados

		ProgressBar(v-if='waiting' mode="indeterminate")
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)

			DataView.dataview.my-2(:value="list" layout="grid")
				template(#grid="props")
					.p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
						Panel.panel-list.ta-center(:header="props.data.nm_procedimento" style='position: relative')
							.ta-left
								p <b>Código:</b> {{ props.data.cd_cbhpm }}
								p <b>Nome do Procedimento:</b> {{ props.data.nm_procedimento }}
								p <b>Valor negociado:</b> {{ props.data.nr_valor_negociado_f }}
								p <b>Tarifa MedClub:</b> {{ props.data.nr_valor_tarifa_f }}
								p <b>Valor Total:</b> {{ somaValores(props.data.nr_valor_tarifa, props.data.nr_valor_negociado) }}


			Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

			Panel.datatable(header='Lista de Procedimentos')
				Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
				DataTable(:value="listaDinamica" editMode='cell' class='editable-cells-table responsive' @cell-edit-complete='onCellEditComplete'
					selectionMode='single' @sort='onSort' :sortField="order.field" :sortOrder="order.sortOrder")
					Column(headerStyle='width: 50px' bodyStyle='text-align:center')
						template(#header)
							Checkbox(v-if='!mostrarSelecionados' v-model='selecionarTodos' binary)
						template(#body='props')
							Checkbox(v-model='selecionados' :value='props.data' :id='props.id' )
					Column(headerStyle='width: 70px' bodyStyle='text-align:center' header='Status')
						template(#body='props')
							ProgressSpinner.waitingStatus(v-if='waitingStatus === props.data.id || waitingSelecionados.find(a=> a === props.data.id)' strokeWidth='6')
							.status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click='alterarStatus(props.data.id)')
								i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
					Column(headerStyle='width: 100px' field='cd_cbhpm' header='Código' :sortable="true")
						template(#body='props')
							p(style='margin:2px 0') <b>{{ props.data.cd_cbhpm }}</b>
							p(style='margin:2px 0' v-if="props.data.cd_codigo_proprio")
								span(v-tooltip.bottom="'Código Próprio'") {{props.data.cd_codigo_proprio}}
					Column(headerStyle='width: 150px' field='nm_estabelecimento' header='Estabelecimento')
					Column(headerStyle='width: 350px' header='Procedimentos' field="nm_procedimento" :sortable="true")
						template(#body='props')
							p(style='margin:2px 0') <b>{{ props.data.nm_procedimento }}</b>
							p(style='margin:2px 0') {{ props.data.ds_procedimento }}
							p(v-for='especialidade in props.data.especialidades' :key='especialidade.id' style='margin:2px 0')
								span <li>{{especialidade.nm_especialidade}}</li>
							p.sinonimos(v-if='props.data.ds_sinonimia')
								span <b>Sinônimos:</b> {{ props.data.ds_sinonimia }}
					//- Column(headerStyle='width: 300px' header='Subgrupos' field="cd_subgrupos" :sortable="true")
					//- 	template(#body='props')
					//- 		div(v-for='subgrupo in props.data.cd_subgrupos')
					//- 			p(style='margin:2px 0') {{ subgrupo.ds_subgrupo }}
					Column(headerStyle='width: 100px' bodyStyle='text-align: center' field='ie_tipo_procedimento' header='Tipo')
						template(#body='props')
							p(style='margin:2px 0') {{ getTipoProcedimento(props.data.ie_tipo_procedimento) }}
					Column(headerStyle='width: 100px' bodyStyle='text-align: center' field='nr_valor_negociado_f' header='Valor Negociado')
						template(#editor='props')
							currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled(
								v-model="props.data['nr_valor_negociado']"
								locale='pt-BR'
								:auto-decimal-mode='true'
								:value-range='{ min: 0 }')
					Column(headerStyle='width: 100px' bodyStyle='text-align: center' field='nr_valor_tarifa_f' header='Valor Tarifa')
						template(#editor='props')
							currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled(
								v-model="props.data['nr_valor_tarifa']"
								locale='pt-BR'
								:auto-decimal-mode='true'
								:value-range='{ min: 0 }')

					Column(headerStyle='width: 100px' bodyStyle='text-align: center' field='ds_grupo_procedimento' header='Grupo')
					Column(headerStyle='width: 110px' bodyStyle='text-align: center' header='Valor Total')
						template(#body='props')
							span(locale='pt-BR' :auto-decimal-mode='true' :value-range='{ min: 0 }') {{ somaValores(props.data.nr_valor_tarifa, props.data.nr_valor_negociado) }}
					Column(headerStyle='width: 200px' header='Ações')
						template(#body='props')
							.ta-center
								ProgressSpinner.waitingRestringir.mr-1(v-if='waitingRestingir === props.data.id' strokeWidth='6')
								Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
									v-else-if='props.data.ie_restrito'
									v-tooltip.top="'Retirar restrição'"
									icon="jam jam-stop-sign"
									@click="alterarRestricao(props.data.id)"
								) 
								Button.p-button-raised.p-button-rounded.p-button-success.mr-1(
									v-else
									v-tooltip.top="'Restringir Agenda'"
									icon="jam jam-stop-sign"
									@click="alterarRestricao(props.data.id)"
								) 
								Button.p-button-raised.p-button-rounded.mr-1(
									v-tooltip.top="'Editar observações'"
									icon="jam jam-document"
									@click="dialogEditarObservacoes_data = props.data; dialogEditarObservacoes = true"
								)
								Button.p-button-raised.p-button-rounded.mr-1(
									v-tooltip.top="'Editar'"
									icon="jam jam-write"
									@click="goToEditar(props.data.id)"
								)
								Button.p-button-raised.p-button-rounded.p-button-danger(
									v-tooltip.top="'Apagar'"
									icon="jam jam-minus-circle"
									@click="dialogApagar = true; dialogApagar_data = props.data"
								)
								Button.p-button-raised.p-button-rounded.p-button-secondary(
									v-if='props.data.cd_subgrupos.length'
									style='background-color: #9B0079; color: white'
									v-tooltip.top="'Subgrupos'"
									icon="jam jam-merge"
									@click='openDialogSubgrupos(props.data.cd_subgrupos)'
								)
				Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
	.procedimentos-listar {

		.dataview {
			@media all and (min-width: 577px) {
				display: none;
			}
		}
		.datatable {
			@media all and (max-width: 576px) {
				display: none;
			}
			.cell {
				padding: 16px 0;
				text-align: center;
				&.ex  {  background-color: #e4f8e1; }
				&.a  { background-color: #faf3dd; }
			}
		}
		.dialogApagar {
			.p-progress-spinner {
				width: 29px;
				height: auto;
			}
		}
		.dialogEditarObservacoes {
			width: 96%;
			max-width: 600px;

			textarea {
				height: 200px;
			}
		}
		.p-multiselect-filter-container {
			width: 90% !important;
		}
		.p-multiselect-filter .p-inputgroup .p-inputtext {
			border-right: 1px !important;
		}
		.label-mostrar-selecionados {
			vertical-align: super;
			font-size: 12px;
			font-weight: 550;
		}
		.add-buttom{
			background-color: #94c860;
			&:hover { background-color: #84b553; }
		}
		.remove-buttom{
			background-color: #d33838;
			&:hover { background-color: #b33030; }
		}
		.status-indicator {
			display: inline-block;
			font-size: 0;
			width: 24px;
			height: 24px;
			line-height: 36px;
			border-radius: 50%;
			background-color: #94c860;
			border: 1px solid #84b553;
			transition: 250ms;
			cursor: pointer;
			&:hover { background-color: #84b553; }
			&.off {
				background-color: #d33838;
				border: 1px solid #b33030;
				&:hover { background-color: #b33030; }
			}
			.jam {
				color: #fff;
			}
		}
		.waitingStatus, .waitingObservacoes {
			width: 29px;
			height: auto;
		}
		.waitingRestringir {
			width: 33px;
			height: auto;
		}
        .textarea-informacoes,
        .textarea-preparo {
            height: 200px !important;
            overflow-y: auto !important;
            resize: none;
        }
		.sinonimos {
			font-size: 12px;
		}
	}
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
	import ProgressBar from 'primevue/progressbar'
	import DataView from 'primevue/dataview'
	import Panel from 'primevue/panel'
	import Paginator from 'primevue/paginator'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Button from 'primevue/button'
	import Tooltip from 'primevue/tooltip'
	import Checkbox from 'primevue/checkbox'
	import Dialog from 'primevue/dialog'
	import MultiSelect from 'primevue/multiselect'
	import InputText from 'primevue/inputtext'
	import InputSwitch from 'primevue/inputswitch'
	import Textarea from 'primevue/textarea'
	import ProgressSpinner from 'primevue/progressspinner'
	import Dropdown from 'primevue/dropdown'
	import MultiSelectEspecialidades from '../CustomComponents/MultiSelectEspecialidades.vue';

	import * as _ from 'lodash'

	import { ProcedimentosEstabelecimento, Estabelecimento , Procedimentos} from './../../middleware'
	import wsConfigs from './../../middleware/configs'
	import DominioValor from "@/middleware/controllers/DominioValor";
	import { processDominiosResponse } from "@/utils";

	export default {
		beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'procedimentos-estabelecimento') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			if(this.$store.state[to.name])
				this.$store.commit('setFilters', this.$store.state[to.name])
			else
				this.$store.commit('setFilters', this.filters)
			next()
		},
		created () {
			this.getEstabelecimentos()
			this.getDominios()
			this.applyFilters()
			Procedimentos.getGrupoProcedimentos().then(response => {
                if (response.status == 200) {
                    this.options.grupo_procedimentos = response.data
                }
            })
		},
		components: { ProgressBar, DataView, Panel, Paginator, DataTable, Textarea, MultiSelectEspecialidades,
			Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, InputSwitch, Checkbox, MultiSelect, Dropdown },
		directives: { tooltip: Tooltip },
		computed: {
			listaDinamica() {
				return this.mostrarSelecionados ? [...this.selecionados] : this.list
			},
			selecionarTodos: {
				get() {
					for (let val of this.list) {
						if (!this.selecionados.find(x => x.id === val.id)) return false
					}
					return true
				},
				set(val) {
					if (val) {
						let filtrado = this.list.filter(item => {
							return this.selecionados.findIndex(x => x.id === item.id) === -1
						})
						this.selecionados = [...this.selecionados, ...filtrado]

					}
					else {

						this.selecionados = this.selecionados.filter(item => {
							return this.list.findIndex(x => x.id === item.id) === -1
						})
					}
				}
			}
		},
		data () {
			return {
				list: [],
				windowInnerWidth: window.innerWidth,
				waiting: false,
				waitingApagar: false,
				waitingStatus: 0,
				waitingRestingir: 0,
				waitingObservacoes: false,
				dialogApagar: false,
				dialogApagar_data: {},
				dialogEditarObservacoes: false,
				dialogEditarObservacoes_data: {},
				dialogSubgrupos: false,
				selecionados: [],
				waitingSelecionados: [],
                waitingSelecionarVarios: false,
				mostrarSelecionados: false,
				dominios: {},
				subgrupos: [],
				filters: {
					cd_estabelecimento_list: '',
					ds_procedimento: '',
					ie_status: true,
					cd_especialidades: '',
					cd_subgrupos: '',
					ie_tipo_agenda: '',
					ie_grupo_estabelecimento: [],
					ie_restrito: null,
					ds_mnemonico: null
				},
				options: {
					grupo_procedimentos: [],
					estabelecimentos: [],
					ie_status: [
						{text: 'TODOS', value: '' },
						{text: 'Ativados', value: true },
						{text: 'Desativados', value: false }
					],
					subgrupos: [],
					ie_grupo_estabelecimento: [],
					tiposProcedimento: [],
					tipo_agenda: [],
					cd_especialidades: [],
					ie_restrito: [
						{ label: "TODOS", value: null },
						{ label: "Restritos", value: true },
						{ label: "Não restito", value: false }
					]
				},
				paginator: {
					page: this.$route.query.pg ?? 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
				estabelecimentoLoaded:true,
				order: {
					field: 'cd_cbhpm',
					sortOrder: 1
				}
			}
		},
		methods: {
			mudaEspecialidades(dado) {
                this.filters.cd_especialidades = dado
                this.applyFilters()
            },
			getEstabelecimentos(){
				Estabelecimento.findAllClean().then(response => {
					if (response.status == 200) {
						response.data.forEach(e => {
							this.options.estabelecimentos.push({ text: e.nm_fantasia, value: e.id })
						})
						this.estabelecimentoLoaded = true
					}
				})
			},
			openDialogSubgrupos(subgrupos) {
				this.subgrupos = subgrupos
				this.dialogSubgrupos = true
			},
			onSort (ev) {
				this.order.field = ev.sortField
				this.order.sortOrder = ev.sortOrder
				this.applyFilters()
			},
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
			goToEditar (id) {
				let nav = {}
				if (this.filters.ds_procedimento) nav.ds_procedimento = this.filters.ds_procedimento
				if (this.filters.cd_estabelecimento) nav.cd_estabelecimento = this.filters.cd_estabelecimento
				this.$router.push(`/procedimentos-estabelecimento/salvar/${ id }/`)
			},
			getList (params) {
				this.waiting = true
				return ProcedimentosEstabelecimento.findAll(params).then(response => {
					if (response.status == 200) {
						this.paginator.count = response.data.count
						response.data.results.forEach(item => {
							item.nr_valor_negociado_f = this.formatPrice(item.nr_valor_negociado)
							item.nr_valor_tarifa_f = this.formatPrice(item.nr_valor_tarifa)
						})
						this.list = response.data.results
					}
					this.waiting = false
					return true
				})
			},
			checkEmpty_ds_procedimento () {
				if (! this.filters.ds_procedimento) this.applyFilters()
			},
			applyFilters (page) {
				this.paginator.page = page || 1

				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )


				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }

				params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`

				Object.keys(this.filters).forEach((key) => {
					if (!([undefined, null]).includes(this.filters[key])) {
						params[key] = key == 'cd_estabelecimento_list' ? _.join(this.filters [key], ',') : this.filters[key]
					}
				})
				this.getList(params)
			},
			onPage (ev) {
				this.applyFilters(ev.page + 1)
			},
			onCellEditComplete (ev) {
				if (this.formatPrice(ev.data.nr_valor_negociado) !== ev.data.nr_valor_negociado_f)
					ProcedimentosEstabelecimento.save({ id: ev.data.id, nr_valor_negociado: ev.data.nr_valor_negociado }).then(response => {
						if (response.status === 200 ) {
							ev.data.nr_valor_negociado_f = this.formatPrice(ev.data.nr_valor_negociado)
							this.$toast.success('Valor atualizado', { duration: 3000 })
						}
					})
				if (this.formatPrice(ev.data.nr_valor_tarifa) !== ev.data.nr_valor_tarifa_f)
					ProcedimentosEstabelecimento.save({ id: ev.data.id, nr_valor_tarifa: ev.data.nr_valor_tarifa }).then(response => {
						if (response.status === 200 ) {
							ev.data.nr_valor_tarifa_f = this.formatPrice(ev.data.nr_valor_tarifa)
							this.$toast.success('Valor atualizado', { duration: 3000 })
						}
					})
			},
			alterarStatus (id) {
				let procedimento = this.list.find(a => a.id === id);
				this.waitingStatus = id
				ProcedimentosEstabelecimento.save({ id, ie_status: ! procedimento.ie_status }).then(response => {
					this.waitingStatus = 0
					if (([200,201]).includes(response.status)) {
						procedimento.ie_status = ! procedimento.ie_status
						if (procedimento.ie_status)
							this.$toast.success('Procedimento habilitado', { duration: 3000 })
						else
							this.$toast.info('Procedimento desabilitado', { duration: 3000 })
					} else if (response.status === 400) {
						this.$toast.error(response.data.detail, { duration: 3000 })
					}
				})
			},
			spinSelecionados(){
				this.selecionados.forEach(selecionado =>{
					this.waitingSelecionados.push(selecionado.id);
				});
			},
			adicionarVarios(){
                this.waitingSelecionarVarios = true;
				let listaIds = []
				this.selecionados.forEach(selecionado =>{
					listaIds.push(selecionado.id);
				});
				ProcedimentosEstabelecimento.saveStatus({ lista: listaIds,  ie_status: true }).then(response => {
					if (response.status === 200) {
						this.selecionados = [];
						this.waitingSelecionados = [];
                        this.waitingSelecionarVarios = false;
						listaIds.forEach(id => {
							let procedimento = this.list.find(a => a.id === id);
							procedimento.ie_status = true
						})
						this.$toast.success('Procedimentos habilitados', { duration: 3000 })
						this.getEstabelecimentos();
					} else if (response.status === 400) {
						this.$toast.error(response.data.detail, { duration: 3000 })
					}
				})
			},
			retirarVarios(){
                this.waitingSelecionarVarios = true;
                let listaIds = []
				this.selecionados.forEach(selecionado =>{
					listaIds.push(selecionado.id);
				});
				ProcedimentosEstabelecimento.saveStatus({ lista: listaIds,  ie_status: false }).then(response => {
					if (response.status === 200) {
						this.selecionados = [];
						this.waitingSelecionados = [];
                        this.waitingSelecionarVarios = false;
						listaIds.forEach(id => {
							let procedimento = this.list.find(a => a.id === id);
							procedimento.ie_status = false
						})
						this.$toast.success('Procedimentos desabilitados', { duration: 3000 })
						this.getEstabelecimentos();
					} else if (response.status === 400) {
						this.$toast.error(response.data.detail, { duration: 3000 })
					}
				})
			},
			salvarObservacoes () {
				this.waitingObservacoes = true
				let dataSend = {
					id: this.dialogEditarObservacoes_data.id,
					ds_informacoes: this.dialogEditarObservacoes_data.ds_informacoes,
					ds_preparo: this.dialogEditarObservacoes_data.ds_preparo
				}
				ProcedimentosEstabelecimento.save(dataSend).then(response => {
					this.waitingObservacoes = false
					if (([200, 201, 204]).includes(response.status)) {
						this.$toast.success('Procedimento atualizado', { duration: 3000 })
						this.applyFilters()
					} else if (response.data.detail) {
						if (typeof response.data.detail == 'string')
							this.$toast.error(response.data.detail, { duration: 3000 })
						else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
					}
				})
			},
			remove () {
				this.waitingApagar = true
				ProcedimentosEstabelecimento.remove(this.dialogApagar_data.id).then(response => {
					this.waitingApagar = false
					if (([200, 201, 204]).includes(response.status)) {
						this.$toast.info('Procedimento removido com sucesso', { duration: 3000 })
						this.dialogApagar = false
						this.applyFilters()
					} else if (response.data.detail) {
						if (typeof response.data.detail == 'string')
							this.$toast.error(response.data.detail, { duration: 3000 })
						else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
					}
				})
			},
			somaValores (val1, val2) {
				return this.formatPrice(val1 + val2)
			},
			getDominios() {
				DominioValor.findAll({ds_mnemonico: ['TABELA_PRECO_TIPO_PROCEDIMENTO', 'SUBGRUPOS', 'TABELA_PRECO_TIPO_AGENDA', 'GRUPO_ESTABELECIMENTOS']}).then(response => {
					this.dominios = processDominiosResponse(response)
					if (response.status === 200) {
						this.options.tiposProcedimento = response.data['TABELA_PRECO_TIPO_PROCEDIMENTO']?.['valores'];
						this.options.ie_grupo_estabelecimento = response.data['GRUPO_ESTABELECIMENTOS']?.valores.map(item => ({ 
							text: item.ds_valor, 
							value: item.ie_valor 
						}));
						this.options.subgrupos = response.data['SUBGRUPOS'].valores.map(subgrupo => ({
                            value: subgrupo.ie_valor,
                            text: subgrupo.ds_valor ,
                            ds_subgrupo: subgrupo.ds_valor,
                            id: subgrupo.ie_valor
                        }))
						this.options.tipo_agenda = response.data['TABELA_PRECO_TIPO_AGENDA'].valores.map(item => ({
                            value: item.ie_valor,
                            label: item.ds_valor
                        }));
					}
				});
			},
			getTipoProcedimento(ie_valor) {
				return this.dominios['TABELA_PRECO_TIPO_PROCEDIMENTO']?.[ie_valor]?.['ds_valor']
			},
			alterarRestricao(id){
				let procedimento = this.list.find(a => a.id === id);
				this.waitingRestingir = id
				ProcedimentosEstabelecimento.save({ id, ie_restrito: ! procedimento.ie_restrito }).then(response => {
					this.waitingRestingir = 0
					if (([200,201]).includes(response.status)) {
						procedimento.ie_restrito = ! procedimento.ie_restrito
						if (procedimento.ie_restrito)
							this.$toast.success('Procedimento restrito', { duration: 3000 })
						else
							this.$toast.info('Restrito retirado', { duration: 3000 })
					} else if (response.status === 400) {
						this.$toast.error(response.data.detail, { duration: 3000 })
					}
				})
			}
		}
	}
</script>
